<template>
    <div class="my-3">
        <div class="text-center mt-2 mb-3">
            <h1 class="h3">
                Referencias en inventario
                {{ selectedMerchant.merchantType | merchantInfo("type") }}
                <strong class="text-primary">
                    {{ selectedMerchant.name }}
                </strong>
                {{ itemsNumber }}
            </h1>
        </div>
        <div class="d-flex flex-wrap">
            <div class="my-3 col-12">
                <products-filters
                    v-model="query"
                    :enabled-filters="$data.$enabledFilters"
                    @enter="reloadItems"
                />
                <div class="d-flex justify-content-center">
                    <vs-button relief @click="reloadItems">
                        Consultar 🔎
                    </vs-button>
                </div>
            </div>
            <div
                class="d-flex justify-content-between flex-column flex-lg-row col-12 my-3"
            >
                <div class="d-flex align-items-center justify-content-around">
                    <tooltip-button
                        v-if="$ability.hasScope('inventory:List')"
                        icon="⬇"
                        message="Descargar todo el inventario"
                        :loading="isLoadingDownload"
                        @click="downLoadInventory"
                    />
                    <tooltip-button
                        v-if="$ability.hasScope('inventory:SyncPrices')"
                        icon="🔁"
                        :message="`Sincronizar precios con ${$siteType}`"
                        @click="syncPricesInventoryComponent"
                    />
                </div>

                <div class="d-flex align-items-center justify-content-around">
                    <vs-pagination
                        v-model="currentPage"
                        :length="pagesNumber"
                        @input="listItems()"
                    />

                    <tooltip-redirect
                        v-if="$ability.hasScope('inventory:Create')"
                        to="/app/inventories/new"
                        message="Nuevo registro de inventario"
                    />
                </div>
            </div>
        </div>
        <div v-if="showTable" class="d-flex flex-wrap">
            <reference-table-item
                v-for="(reference, index) in itemsCurrent"
                :key="index"
                class="col-md-11 col-12 mx-auto"
                :merchant="selectedMerchant"
                :reference="{ ...reference, quantity: reference.stockMerchant }"
            />
            <div v-if="!itemsCurrent.length" class="col-11 mx-auto mt-4">
                <strong>
                    Nuestro sistema NO encuentra resultados. Intenta modificar
                    los filtros de búsqueda.
                </strong>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { downloadFileMassiveInventory } from "@/common/lib/excel";

import ProductsFilters from "@/components/products/ProductsFilters.vue";
import ReferenceTableItem from "@/components/references/ReferenceTableItem.vue";
import TooltipButton from "@/components/utils/TooltipButton.vue";
import TooltipRedirect from "@/components/utils/TooltipRedirect.vue";

export default {
    name: "InventoryIndex",
    components: {
        ReferenceTableItem,
        ProductsFilters,
        TooltipButton,
        TooltipRedirect
    },
    data: () => ({
        isLoadingDownload: false,
        isLoading: false,
        loaderInstance: null,
        itemsCurrent: [],
        $enabledFilters: ["brand", "line", "keyword", "barcode"],
        showTable: false,
        currentPage: 1,
        pagesNumber: 0,
        itemsNumber: 0,
        keySearch: "",
        query: {}
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor"]),
        ...mapState("commons", ["selectedMerchant"]),
        ...mapState("inventories", ["references"])
    },
    watch: {
        keySearch(value) {
            if (value) {
                const lower = value.toLowerCase();
                this.itemsCurrent = this.references.filter((item) =>
                    item.product.name.toLowerCase().includes(lower)
                );
            }
        },
        selectedMerchant() {
            if (this.showTable) {
                this.currentPage = 1;
                this.listItems(true);
            }
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("inventory:List", "/app", true))) return;
        this.listItems(true);
    },
    methods: {
        ...mapActions("inventories", [
            "listMassiveInventories",
            "listReferencesInInventory",
            "syncPricesInventory"
        ]),
        async listItems(complete = false) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square-rotate" });
            try {
                const { items, pagination } =
                    await this.listReferencesInInventory({
                        ...this.query,
                        complete,
                        page: this.currentPage,
                        merchantId: this.selectedMerchant.merchantId,
                        merchantType: this.selectedMerchant.merchantType
                    });
                this.itemsCurrent = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async reloadItems() {
            this.currentPage = 1;
            await this.listItems(true);
        },
        async syncPricesInventoryComponent() {
            let reload = false;
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: "Vas a sincronizar los precios de tu inventario ?",
                text: `Quieres que TODOS los precios del comercio ${this.selectedMerchant.name} tendrá el mismo precio de la referencia en el ${this.$siteType}. Para reestablecer un precio debes entrar el detalle de la referencia.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33ddd",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });
            if (!dialogResult.isConfirmed) {
                return;
            }

            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({
                type: "square-rotate"
            });
            try {
                await this.syncPricesInventory({
                    merchantType: this.selectedMerchant.merchantType,
                    merchantId: this.selectedMerchant.merchantId
                });
                reload = true;
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }

            if (reload) {
                this.reloadItems();
            }
        },
        async downLoadInventory() {
            this.isLoadingDownload = true;
            try {
                const { items } = await this.listMassiveInventories(
                    this.selectedMerchant
                );
                await downloadFileMassiveInventory(
                    "/template_massive_load_inventories.xlsx",
                    `inventory_${this.selectedMerchant.name}`,
                    items
                );
            } catch (error) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoadingDownload = false;
            }
        }
    }
};
</script>
